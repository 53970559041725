import React, {useEffect, useState} from 'react';
import TextField from '@material-ui/core/TextField';
import {makeStyles} from '@material-ui/core/styles';

import {useParams, useLocation} from "react-router-dom";
import {redirection} from "./utils";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
        },
    },
}));

const MIN_AMOUNT = 100;
const MIN_PHONE_LEN = 8;

export default function SamaMoneyForm() {
    const classes = useStyles();
    const queries = useQuery();
    const [phoneClient, setPhoneClient] = useState("");
    const [description, setDescription] = useState("");
    const [amount, setAmount] = useState("");
    const [showAmount, setShowAmount] = useState(false);
    const [error, setError] = useState(null);
    const [disabled, setDisabled] = useState(true);
    const [processing, setProcessing] = useState(false);
    const [succeeded, setSucceeded] = useState(false);
    const [orderId, setOrderId] = useState(queries.get("order_id"));
    const [clientName, setClientName] = useState('Djamma Sama Money Payment');

    const name = queries.get('name');
    const email = queries.get('email');
    const phone = queries.get('phone');
    const redirect = queries.get('redirect');

    document.title = clientName

    const params = useParams();
    const authorization = params.token;

    const createPayment = async () => {
        // Create PaymentIntent as soon as the page loads
        let body = {
            phoneClient,
            amount,
            description,
            metadata: queries
        };
        if(orderId) {
            body['orderId'] = orderId;
        }
        return fetch("/api/samamoney/payment", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${authorization}`
            },
            body: JSON.stringify(body)
        })
            .then(res => {
                return res.json();
            });
    };

    const retrieveClientInformation = async () => {
        if (authorization && authorization.length > 0) {
            let data = await fetch(`/api/samamoney/client`, {
                headers: {
                    "Accept": "application/json",
                    "Authorization": `Bearer ${authorization}`
                }
            }).then(res => res.json())
            if (data.code && data.code / 100 !== 2) {
                setError(data);
            } else {
                setClientName(data.name);
            }
        }
    }

    const retrieveInformation = () => {
        if (authorization && authorization.length > 0) {
            setShowAmount(false);
            if (orderId && orderId.length > 0) {
                setShowAmount(false);
                fetch(`/api/samamoney/complete?orderId=${orderId}`, {
                    headers: {
                        "Accept": "application/json",
                        "Authorization": `Bearer ${authorization}`
                    }
                })
                    .then(res => {
                        if (res.status !== 200) {
                            return Promise.reject(res)
                        }
                        return res.json();
                    })
                    .then(data => {
                        console.log('data', data);
                        setAmount(data.amount || '');
                        setDescription(data.description || '');
                        setPhoneClient(data.phoneClient || '');
                        setSucceeded(data.success);
                        setDisabled(false);
                    })
                    .catch(error => {
                        console.log('error', error);
                        setShowAmount(true);
                    });
            } else {
                setShowAmount(true);
            }
        }
        retrieveClientInformation();
    }

    useEffect(retrieveInformation, []);

    const handleSubmit = (ev) => {
        ev.preventDefault();
        setProcessing(true);
        createPayment().then(res => {
            console.log('res', res);
            if (res.code) {
                setError(`Payment failed ${res.message || res.description}`);
                setProcessing(false);
            } else {
                setError(null);
                setProcessing(false);
                setDisabled(true);
                let entity = res.entity;
                if (!Boolean(entity)) {
                    entity = res;
                }
                setOrderId(entity.orderId);
                setError(entity.message);
                setTimeout(() => {
                    redirection(redirect);
                }, 3000);
            }
        }).catch(error => {
            console.log('error', error);
            setError(`Payment failed ${error.message}`);
            setProcessing(false);
        });
    }
    return (
        <form className={classes.root} noValidate autoComplete="off" onSubmit={handleSubmit}>
            <h1>{clientName}</h1>
            {orderId && <h2>Order Id : {orderId}</h2>}
            <h3>{name} {email} {phone}</h3>
            <TextField error={!Boolean(phoneClient) || phoneClient.length < MIN_PHONE_LEN}
                       fullWidth
                       id="standard-error"
                       label="Phone Number"
                       placeholder={"22391445058"}
                       value={phoneClient}
                       disabled={succeeded}
                       onChange={e => {
                           setPhoneClient(e.target.value);
                       }}
            />
            <TextField error={amount < MIN_AMOUNT}
                       fullWidth
                       id="standard-error"
                       label="Amount"
                       placeholder={"200"}
                       type={"text"}
                       value={amount}
                       disabled={!showAmount || succeeded}
                       onChange={e => {
                           setAmount(e.target.value * 1)
                       }}
            />
            <TextField fullWidth
                       id="standard-error"
                       label="Description"
                       value={description}
                       disabled={succeeded}
                       onChange={e => {
                           setDescription(e.target.value)
                       }}
            />

            <button
                disabled={processing || succeeded || disabled || ((phoneClient || '').length < MIN_PHONE_LEN || amount * 1 < MIN_AMOUNT)}
                id="submit"
            >
                <span id="button-text">
                  {processing ? (
                      <div className="spinner" id="spinner"/>
                  ) : (
                      "Sama Money Payment"
                  )}
                </span>
            </button>
            {/* Show any error that happens when processing the payment */}
            {error && (
                <div className="card-error" role="alert">
                    {error}
                </div>
            )}
            {/* Show a success message upon completion */}
            <p className={succeeded ? "result-message" : "result-message hidden"}>
                Payment succeeded for this order
            </p>
        </form>
    );
}