import React, {useEffect, useState} from 'react';
import {CardElement, useElements, useStripe} from '@stripe/react-stripe-js';
import {useLocation, useParams} from "react-router-dom";
import TextField from '@material-ui/core/TextField';

import {makeStyles} from '@material-ui/core/styles';
import {Grid} from "@material-ui/core";
import {redirection} from "./utils";

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
        },
    },
    button: {
        marginTop: 10
    }
}));

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const CARD_OPTIONS = {
    iconStyle: 'solid',
    style: {
        base: {
            color: "#32325d",
            fontFamily: 'Arial, sans-serif',
            fontSmoothing: "antialiased",
            fontSize: "16px",
            "::placeholder": {
                color: "#32325d"
            }
        },
        invalid: {
            color: "#fa755a",
            iconColor: "#fa755a"
        }
    },
    hidePostalCode: true
};

const CardField = ({onChange, disabled}) => (
    <div className="FormRow">
        <CardElement options={{...CARD_OPTIONS, disabled}} onChange={onChange}/>
    </div>
);

const Field = ({
                   label,
                   id,
                   type,
                   placeholder,
                   required,
                   autoComplete,
                   value,
                   onChange,
                   fullWidth,
                   helperText,
                   error,
                   disabled,
               }) => (
    <div className="FormRow">
        <TextField
            error={error}
            fullWidth={fullWidth}
            helperText={helperText}
            label={label}
            className="FormRowInput"
            id={id}
            type={type}
            disabled={disabled}
            placeholder={placeholder}
            required={required}
            autoComplete={autoComplete}
            value={value}
            onChange={onChange}
        />
    </div>
);

const SubmitButton = ({processing, error, children, disabled, succeeded, className}) => (
    <button
        className={`${className} SubmitButton ${error ? 'SubmitButton--error' : ''}`}
        type="submit"
        disabled={processing || disabled || succeeded}
    >
        {processing ? 'Processing...' : children}
    </button>
);

const ErrorMessage = ({children}) => (
    <div className="ErrorMessage" role="alert">
        <svg width="16" height="16" viewBox="0 0 17 17">
            <path
                fill="#FFF"
                d="M8.5,17 C3.80557963,17 0,13.1944204 0,8.5 C0,3.80557963 3.80557963,0 8.5,0 C13.1944204,0 17,3.80557963 17,8.5 C17,13.1944204 13.1944204,17 8.5,17 Z"
            />
            <path
                fill="#6772e5"
                d="M8.5,7.29791847 L6.12604076,4.92395924 C5.79409512,4.59201359 5.25590488,4.59201359 4.92395924,4.92395924 C4.59201359,5.25590488 4.59201359,5.79409512 4.92395924,6.12604076 L7.29791847,8.5 L4.92395924,10.8739592 C4.59201359,11.2059049 4.59201359,11.7440951 4.92395924,12.0760408 C5.25590488,12.4079864 5.79409512,12.4079864 6.12604076,12.0760408 L8.5,9.70208153 L10.8739592,12.0760408 C11.2059049,12.4079864 11.7440951,12.4079864 12.0760408,12.0760408 C12.4079864,11.7440951 12.4079864,11.2059049 12.0760408,10.8739592 L9.70208153,8.5 L12.0760408,6.12604076 C12.4079864,5.79409512 12.4079864,5.25590488 12.0760408,4.92395924 C11.7440951,4.59201359 11.2059049,4.59201359 10.8739592,4.92395924 L8.5,7.29791847 L8.5,7.29791847 Z"
            />
        </svg>
        {children}
    </div>
);

const ResetButton = ({onClick}) => (
    <button type="button" className="ResetButton" onClick={onClick}>
        <svg width="32px" height="32px" viewBox="0 0 32 32">
            <path
                fill="#FFF"
                d="M15,7.05492878 C10.5000495,7.55237307 7,11.3674463 7,16 C7,20.9705627 11.0294373,25 16,25 C20.9705627,25 25,20.9705627 25,16 C25,15.3627484 24.4834055,14.8461538 23.8461538,14.8461538 C23.2089022,14.8461538 22.6923077,15.3627484 22.6923077,16 C22.6923077,19.6960595 19.6960595,22.6923077 16,22.6923077 C12.3039405,22.6923077 9.30769231,19.6960595 9.30769231,16 C9.30769231,12.3039405 12.3039405,9.30769231 16,9.30769231 L16,12.0841673 C16,12.1800431 16.0275652,12.2738974 16.0794108,12.354546 C16.2287368,12.5868311 16.5380938,12.6540826 16.7703788,12.5047565 L22.3457501,8.92058924 L22.3457501,8.92058924 C22.4060014,8.88185624 22.4572275,8.83063012 22.4959605,8.7703788 C22.6452866,8.53809377 22.5780351,8.22873685 22.3457501,8.07941076 L22.3457501,8.07941076 L16.7703788,4.49524351 C16.6897301,4.44339794 16.5958758,4.41583275 16.5,4.41583275 C16.2238576,4.41583275 16,4.63969037 16,4.91583275 L16,7 L15,7 L15,7.05492878 Z M16,32 C7.163444,32 0,24.836556 0,16 C0,7.163444 7.163444,0 16,0 C24.836556,0 32,7.163444 32,16 C32,24.836556 24.836556,32 16,32 Z"
            />
        </svg>
    </button>
);

export default function CheckoutCardForm() {
    const queries = useQuery();

    const stripe = useStripe();
    const elements = useElements();
    const [error, setError] = useState(null);
    const [cardComplete, setCardComplete] = useState(false);
    const [processing, setProcessing] = useState(false);
    const [paymentMethod, setPaymentMethod] = useState(null);

    const name = queries.get('name');
    const email = queries.get('email');
    const phone = queries.get('phone');
    const redirect = queries.get('redirect');

    const [billingDetails, setBillingDetails] = useState({
        email: email || '',
        phone: phone || '',
        name: name || '',
    });

    const [succeeded, setSucceeded] = useState(false);
    const [hasToken, setHasToken] = useState(true);
    const [showAmount, setShowAmount] = useState(false);
    const [amount, setAmount] = useState(25.0);
    const [currency, setCurrency] = useState('eur');
    const [clientSecret, setClientSecret] = useState(queries.get("payment_token"));
    const [orderId, setOrderId] = useState(queries.get("order_id"));
    const [clientName, setClientName] = useState('Djamma Stripe Payment');

    document.title = clientName

    const params = useParams();
    const authorization = params.token;

    const createIntent = async () => {
        // Create PaymentIntent as soon as the page loads
        return fetch("/api/stripe/payment", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${authorization}`
            },
            body: JSON.stringify({
                "currency": currency || "eur", "amount": amount * 100,
                metadata: queries
            })
        }).then(res => {
            return res.json();
        });
    };

    const retrieveClientInformation = async () => {
        if (authorization && authorization.length > 0) {
            let data = await fetch(`/api/stripe/client`, {
                headers: {
                    "Accept": "application/json",
                    "Authorization": `Bearer ${authorization}`
                }
            }).then(res => res.json())
            if (data.code && data.code / 100 !== 2) {
                setError(data);
            } else {
                setClientName(data.name);
            }
        }
    }

    const retrieveInformation = () => {
        if (authorization && authorization.length > 0) {
            setHasToken(true);
            setShowAmount(false);
            if (orderId && orderId.length > 0) {
                setShowAmount(false);
                fetch(`/api/stripe/complete?orderId=${orderId}`, {
                    headers: {
                        "Accept": "application/json",
                        "Authorization": `Bearer ${authorization}`
                    }
                })
                    .then(res => {
                        if (res.status !== 200) {
                            return Promise.reject(res)
                        }
                        return res.json();
                    })
                    .then(data => {
                        console.log('data', data);
                        setAmount(data.amount / 100.);
                        setCurrency(data.currency);
                        setSucceeded(data.success);
                        if (data.token_id && !clientSecret) {
                            setClientSecret(data.token_id)
                        }
                    })
                    .catch(error => {
                        console.log('error', error);
                        setShowAmount(true);
                    });
            } else {
                setShowAmount(true);
            }
        } else {
            setHasToken(!clientSecret || clientSecret.length === 0);
        }
        retrieveClientInformation();
    }

    useEffect(retrieveInformation, []);

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js has not loaded yet. Make sure to disable
            // form submission until Stripe.js has loaded.
            return;
        }

        if (error) {
            elements.getElement('card').focus();
            return;
        }

        if (cardComplete) {
            setProcessing(true);
        }

        const confirmPayment = async (clientSecret) => {
            let details = {};
            for (let key in billingDetails) {
                if (billingDetails[key].trim().length > 0) {
                    details[key] = billingDetails[key];
                }
            }
            console.log('details', details)
            let payload = await stripe.confirmCardPayment(clientSecret, {
                payment_method: {
                    card: elements.getElement(CardElement),
                    billing_details: details
                }
            });
            console.log('payload', payload);
            if (payload.error) {
                setError(payload.error);
                setProcessing(false);
            } else {
                setError(null);
                setProcessing(false);
                setSucceeded(true);
                setPaymentMethod(payload.paymentIntent);
                setOrderId(payload.paymentIntent.id);
                redirection(redirect)
            }
        };
        if (clientSecret) {
            confirmPayment(clientSecret);
        } else {
            createIntent().then(data => {
                console.log('data', data);
                confirmPayment(data.client_secret);
            }).catch(error => {
                console.log('error', error);
                setError(error);
                setProcessing(false);
            });
        }
    };

    const reset = () => {
        setError(null);
        setProcessing(false);
        setPaymentMethod(null);
        setBillingDetails({
            email: '',
            phone: '',
            name: '',
        });
    };

    const classes = useStyles();

    return paymentMethod ? (
        <div className="Result">
            <div className="ResultTitle" role="alert">
                Payment {paymentMethod.status}
            </div>
            <div className="ResultMessage">
                Merci !
                <p>Payment: {paymentMethod.id}</p>
            </div>
            <ResetButton onClick={reset}/>
        </div>
    ) : (
        <div className={classes.root}>
            <form className="Form" onSubmit={handleSubmit}>
                <h1>{clientName}</h1>
                {orderId && <h2>Order Id : {orderId}</h2>}
                {!succeeded && <Grid className="FormGroup">
                    <Field
                        label="Nom complet"
                        fullWidth
                        id="name"
                        type="text"
                        placeholder="Jane Doe"
                        required
                        autoComplete="name"
                        value={billingDetails.name}
                        onChange={(e) => {
                            setBillingDetails({...billingDetails, name: e.target.value});
                        }}
                    />
                    <Field
                        label="Email"
                        fullWidth
                        id="email"
                        type="email"
                        placeholder="janedoe@gmail.com"
                        autoComplete="email"
                        disabled={Boolean(email) && email.trim().length > 0}
                        value={billingDetails.email}
                        onChange={(e) => {
                            setBillingDetails({...billingDetails, email: e.target.value});
                        }}
                    />
                    <Field
                        label="Téléphone"
                        fullWidth
                        id="phone"
                        type="tel"
                        placeholder="0612345789"
                        autoComplete="tel"
                        disabled={phone && phone.trim().length > 0}
                        value={billingDetails.phone}
                        onChange={(e) => {
                            setBillingDetails({...billingDetails, phone: e.target.value});
                        }}
                    />
                </Grid>}
                <Grid className="FormGroup">
                    <Field error={amount < 5}
                           fullWidth
                           id="standard-error"
                           label="Amount"
                           type={"number"}
                           value={amount}
                           disabled={!showAmount}
                           helperText={amount < 5 ? "Incorrect Amount." : ""}
                           onChange={e => {
                               setAmount(e.target.value * 1)
                           }}
                    />
                </Grid>
                <Grid>
                    <CardField
                        onChange={(e) => {
                            setError(e.error);
                            setCardComplete(e.complete);
                        }}
                        disabled={!hasToken || succeeded}
                    />
                </Grid>
                {error && <ErrorMessage>{error.message}</ErrorMessage>}
                <SubmitButton className={classes.button} processing={processing} error={error} disabled={!stripe}
                              succeeded={succeeded}>
                    Pay {amount} €
                </SubmitButton>
                <p className={succeeded ? "result-message" : "result-message hidden"}>
                    Payment complété. Vous pouvez fermer la fenêtre.
                </p>
            </form>
        </div>
    );
};

